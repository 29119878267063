<template>
    <div>
        <div class="about-bg d-none d-sm-flex justify-center flex-column">
        <div class="banner">
          <div class="text-title white--text text-left">
            {{ $t('Page.Eco.title1') }}
          </div>
            <div class="mt-6 text-h5 font-weight-regular white--text text-left">
              {{ $t('Page.Eco.title2') }}
            </div>
            <div class="mt-4 text-h5 font-weight-regular white--text text-left">
              {{ $t('Page.Eco.title3') }}
            </div>
        </div>
      </div>

      <div class="about-bg d-block d-sm-none">
        <div class="banner text-center white--text px-2 pt-8 pb-3">
          <div class="text-h6">{{ $t('Page.Eco.title1') }}</div>
          <div class="text-body-2 mt-5">{{ $t('Page.Eco.title2') }}</div>
          <div class="text-body-2 mt-2">{{ $t('Page.Eco.title3') }}</div>
        </div>
      </div>

      <div class="px-sm-2 px-md-14">
        <div class="mt-10">
          <v-card class="pt-10">
            <div class="  text-h6 mb-10 font-weight-bold text-center ">{{ $t('Page.Eco.chartname') }}</div>
           <v-row class="d-flex justify-space-around align-center">
            <v-col cols="12"  sm="12" md="7" lg="8">
              <v-card elevation="0" class="pa-6">
                <div id="chart" style="height:460px;"></div>
              </v-card>
            </v-col>
            <v-col cols="12"  sm="12" md="5" lg="4" style="height:100%; border-left: 2px solid #333;">
            <div>
              <v-card elevation="0" class="  pa-6 text-body-1">
                <p class="pb-0">
                  <span>{{ $t('Page.Dist.ChartS1') }}：</span>50%
                </p>
                <br/>
                <p class="pb-0">
                  {{ $t('Page.Dist.ChartS2') }}：10%<br />
                </p>
                <br />
                <p class="pb-0">
                  {{ $t('Page.Dist.ChartS3') }}：10%<br />
                </p>
                <br />
                <p class="pb-0">
                  {{ $t('Page.Dist.ChartS4') }}：10%<br />
                </p>
                <br />
                <p class="pb-0">
                  {{ $t('Page.Dist.ChartS5') }}：15%<br />
                </p>
                <br />
                <p class="pb-0">
                {{ $t('Page.Dist.ChartS6') }}：5%<br />
                </p>
              </v-card>
            </div>
            </v-col>
          </v-row>
          </v-card>
        </div>

        <div class="mt-14">
            <v-card class="pa-10">
              <div class="text-h6 mb-4 font-weight-bold text-center ">{{ $t('Page.Eco.boxname') }}</div>
              <div class="text-body-1 mb-14 font-weight-bold text-center ">{{ $t('Page.Eco.boxTitle') }}</div>
              <v-row class="d-flex justify-space-around align-center">
                <v-col cols="12" sm="6" md="4" lg="2">
                  <div class="d-flex flex-column justify-center align-center">
                    <div class="box-cricel d-flex justify-center align-center">
                      <img width="60" src="../../assets/images/box1.svg" alt="">
                    </div>
                    <div class="text-body-1 my-6 font-weight-bold text-center ">{{ $t('Page.Eco.boxTitle1') }}</div>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="2">
                  <div class="d-flex flex-column justify-center align-center">
                    <div class="box-cricel d-flex justify-center align-center">
                      <img width="60" src="../../assets/images/box2.svg" alt="">
                    </div>
                    <div class="text-body-1 my-6 font-weight-bold text-center ">{{ $t('Page.Eco.boxTitle2') }}</div>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="2">
                  <div class="d-flex flex-column justify-center align-center">
                    <div class="box-cricel d-flex justify-center align-center">
                      <img width="60" src="../../assets/images/box3.svg" alt="">
                    </div>
                    <div class="text-body-1 my-6 font-weight-bold text-center ">{{ $t('Page.Eco.boxTitle3') }}</div>
                  </div>
                </v-col>
              <!-- </v-row>
              <v-row class="d-flex justify-space-around align-center mt-10"> -->
                <v-col cols="12" sm="6" md="4" lg="2">
                  <div class="d-flex flex-column justify-center align-center">
                    <div class="box-cricel d-flex justify-center align-center">
                      <img width="60" src="../../assets/images/box4.svg" alt="">
                    </div>
                    <div class="text-body-1 my-6 font-weight-bold text-center ">{{ $t('Page.Eco.boxTitle4') }}</div>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="2">
                  <div class="d-flex flex-column justify-center align-center">
                    <div class="box-cricel d-flex justify-center align-center">
                      <img width="60" src="../../assets/images/box5.svg" alt="">
                    </div>
                    <div class="text-body-1 my-6 font-weight-bold text-center ">{{ $t('Page.Eco.boxTitle5') }}</div>
                  </div>
                </v-col>
              </v-row>
            </v-card>
        </div>

        <div class="mt-10">
            <v-card class="pa-10">
              <div class="text-h6 mb-4 font-weight-bold text-center ">{{ $t('Page.Eco.tablename') }}</div>
              <v-simple-table class="mt-10">
            <thead>
              <tr>
                <th class="text-body-1">{{ $t('Page.Eco.Table.Time') }}</th>
                <th class="text-body-1">{{ $t('Page.Eco.Table.Burnproject') }}</th>
                <th class="text-body-1">{{ $t('Page.Eco.Table.BurnAmount') }}</th>
                <th class="text-body-1">{{ $t('Page.Eco.Table.BurnValue') }}</th>
                <th class="text-body-1">{{ $t('Page.Eco.Table.Desc') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="grey--text text--darken-2 text-body-1"> </td>
                <td class="grey--text text--darken-2 text-body-1"> </td>
                <td class="grey--text text--darken-2 text-body-1"> </td>
                <td class="grey--text text--darken-2 text-body-1"> </td>
                <td class="grey--text text--darken-2 text-body-1"> </td>
              </tr>
            </tbody>
          </v-simple-table>
            </v-card>
        </div>
      </div>
      <Footer></Footer>
    </div>
</template>

<script>
import Footer from '../../components/Footer.vue';
import * as echarts from 'echarts';

export default {
  components: {
    Footer
  },
  mounted(){
    this.drawChart();
  },
  methods: {
    drawChart() {
      var chartDom = document.getElementById('chart');
      var myChart = echarts.init(chartDom);
      var option;

      var type1 = this.$i18n.t('Page.Dist.ChartS1');
      var type2 = this.$i18n.t('Page.Dist.ChartS2');
      var type3 = this.$i18n.t('Page.Dist.ChartS3');
      var type4 = this.$i18n.t('Page.Dist.ChartS4');
      var type5 = this.$i18n.t('Page.Dist.ChartS5');
      var type6 = this.$i18n.t('Page.Dist.ChartS6');

      option = {
        title: {
          text: '',
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}:  {c}%'
        },
        legend: {
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: '50%',
            data: [
              { value: 50, name: type1 },
              { value: 10, name: type2 },
              { value: 10, name: type3 },
              { value: 10, name: type4 },
              { value: 15, name: type5 },
              { value: 5, name: type6 }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };

      option && myChart.setOption(option);

    }
  },
  watch:{
        '$i18n.locale'(){
        this.drawChart()
        }
    },
}
</script>
  
<style lang="scss">
  .page-con {
    width: 100vw;
    min-height: calc(100vh - 289px);
    padding: 80px 120px;
    background: #F8F4F0;
  }

     .about-bg {
      padding: 50px;
      width: 100vw;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.54), rgba(0, 0, 0, 0.54)),
        url("../../assets/images/foundation_bg.png") top right;
    }
    .banner {
      padding: 170px 120px 120px;

      .text-title {
        font-size: 64px;
        font-family: PingFang SC, PingFang SC-Semibold;
        font-weight: 600;
      }
    }

    .subtit {
      position: relative;
    }

    .subtit::before {
      content: '';
      position: absolute;
      width: 60%;
      height: 7px;
      // background: #ac8250;
      background: #ddd;
      bottom: -20px;
      left: 0;
    }

    .v-data-table th {
      // background: #AC8250;
      background: #EBDFD3;
    }
    .box-cricel{
      width: 80px;
      height: 80px;
      border-radius: 50%;
      border: 3px solid #EBDFD3;
      img{
        width: 50%;
        height: 50%;
      }
    }

    // .v-data-table td {
    //   background: #EBDFD3;
    // }

  @media screen and (max-width: 960px) {
    .banner {

      .content {
        font-size: 14px;

        .tit {
          font-size: 38px;
        }
      }
    }

    .about {
      padding: 30px 24px;
    }

    .twitter, .medium, .discord {
      width: 30px!important;
    }
  }
</style>